<template>
    <div class="app-container">
        <div class="filter-container">
            <el-input
                clearable
                v-model="query.publicField"
                :placeholder='$i18n.t("common.UserID_IP_LoginDevice")'
                style="width: 200px"
                class="filter-item"
                controls-position="right"
            ></el-input>
            <el-select v-model="query.loginType"     :placeholder='$i18n.t("common.Loginmethod")' style="width: 200px; margin-left: 10px" clearable>
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
            <dataSelect
                style="width: 200px"
                class="filter-item"
                v-model="query.countryCode"
                clearable
                :codeType="'countrys'"
                :placeholder='$i18n.t("common.country")'
            ></dataSelect>
            <!-- <dataSelect
                style="width: 200px"
                class="filter-item"
                v-model="query.brandCode"
                clearable
                :codeType="'brand'"

                placeholder="品牌"
            ></dataSelect> -->

            <el-date-picker
                class="filter-item"
                v-model="query.logintimeStartAndEnd"
                type="datetimerange"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
                :range-separator='$i18n.t("common.to")'
                :start-placeholder='$i18n.t("common.starttime")'
                :end-placeholder='$i18n.t("common.Endtime")'
                align="right"
                :default-time="['00:00:00','23:59:59']"
            >
            </el-date-picker>

            <dataSelect
                    style="width: 180px; margin-left: 10px"
                    class="filter-item"
                    v-model="query.loginPlatform"
                    clearable
                    :codeType="'platforms'"
                    :placeholder='$i18n.t("common.platform")'
            ></dataSelect>

            <el-button class="filter-item" icon="el-icon-search" type="primary" @click="handleQuery">   {{ $i18n.t("common.query") }} </el-button>

            <el-button class="filter-item" icon="el-icon-search" type="primary" @click="cleanQuery" style="margin-left: 10px" size="small ">
                {{ $i18n.t("common.Reset") }}
            </el-button>
        </div>

        <el-table v-loading="listLoading" :data="list" @selection-change="selectionChange" border stripe style="width: 100%">
            <el-table-column :label='$i18n.t("common.user")' prop="userId" align="center">
                <template slot-scope="{ row }">
                    <span>{{ row.userId }}</span>
                </template>
            </el-table-column>
            <el-table-column :label='$i18n.t("common.logintime")' prop="logintime" align="center">
                <template slot-scope="{ row }">
                    <span>{{ new Date(row.loginTime).toLocaleString() }}</span>
                </template>
            </el-table-column>

            <el-table-column label="登出时间" prop="loginOutTime" align="center" :formatter="formatDate" width='150'></el-table-column>

            <el-table-column
                    prop="loginPlatform"
                    :label="$i18n.t('common.platform')"
                    align="center"
                    show-overflow-tooltip
            ></el-table-column>
            <el-table-column :label='$i18n.t("common.Loginmethod")' prop="loginType" align="center">
                <template slot-scope="{ row }">
                    <span>{{ row.loginType }}</span>
                </template>
            </el-table-column>
            <el-table-column :label='$i18n.t("common.IPaddress")' prop="ipaddress" align="center">
                <template slot-scope="{ row }">
                    <span>{{ row.ipAddress }}</span>
                </template>
            </el-table-column>

            <el-table-column :label='$i18n.t("common.CountryCode")' prop="countrycode" align="center">
                <template slot-scope="{ row }">
                    <span>
                        {{ $dict.getDictValue('countrys', row.countryCode) }}
                    </span>
                </template>
            </el-table-column>

    
            <el-table-column :label='$i18n.t("common.Logindevice")'  prop="loginequipment" align="center">

                <template slot-scope="{ row }">
                    <span>{{ row.loginEquipment }}</span>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination
            style="margin-top: 10px"
            background
            v-show="total > 0"
            @current-change="doQueryList"
            :current-page="query.page"
            :page-size="query.size"
            layout="total, prev, pager, next, jumper"
            :total="total"
        ></el-pagination>
    </div>
</template>

<script>
import Pagination from '@/components/common/Pagination';
import DataSelect from '@/components/common/DataSelect';
import moment from 'moment';

export default {
    name: 'LoginLogTable',
    components: {
        Pagination,
        DataSelect,
        moment
    },
    data() {
        return {
            list: [],
            total: 0,
            listLoading: true,
            query: {
                page: 1,
                limit: 10,
                userId: null,
                publicField: null,
                logintimeStartAndEnd: null,
                ipAddress: null,
                loginType: this.options,
                countryCode: null,
                loginEquipment: null,
                systampStart: '',
                systampEnd: '',
                loginPlatform: null
            },
            selectItems: [],
            options: [
                {
                    value: 'email',
                    label: this.$i18n.t("common.mailbox")
                },
                {
                    value: 'Facebook',
                    label: 'Facebook'
                },
                {
                    value: 'Instagram',
                    label: 'Instagram'
                },
                {
                    value: 'GooglePlus',
                    label: 'Google'
                },
                {
                    value: 'Whatsapp',
                    label: 'Whatsapp'
                }
            ],
            value: ''
        };
    },
    watch: {
        $route(to, from) {
            // 在mounted函数执行的方法，放到该处
            if(to.path === '/b2c/loginlog/list'){
                this.routerHandler();
            }
            
        }
    },
    created() {
        this.routerHandler();
    },
    methods: {
        /**
         * 路由跳转处理
         */
        routerHandler() {
            var userId = this.$route.params.userId;
            if (userId) {
                this.query.publicField = userId;
            }
            this.doQueryList(1);
        },
        /**
         * 选择框变化
         */
        selectionChange(val) {
            this.selectItems = val;
        },
        /**
         * 触发搜索操作
         */
        handleQuery() {
            this.doQueryList(1);
        },
        /**
         * 表单重置
         */
        cleanQuery() {
            this.query = [];
            this.doQueryList(1);
        },
        /**
         * 执行列表查询
         */
        doQueryList(page) {
            this.query.page = page;
            this.listLoading = true;
            if (this.query.logintimeStartAndEnd != null) {
                this.query.beginTime = moment(this.query.logintimeStartAndEnd[0]).subtract(8,'hour').format('YYYY-MM-DD HH:mm:ss');
                this.query.endTime = moment(this.query.logintimeStartAndEnd[1]).subtract(8,'hour').format('YYYY-MM-DD HH:mm:ss');
                // this.query.beginTime = moment(this.query.logintimeStartAndEnd[0]).format('YYYY-MM-DD HH:mm:ss');
                // this.query.endTime = moment(this.query.logintimeStartAndEnd[1]).format('YYYY-MM-DD HH:mm:ss');
            } else {
                this.query.beginTime = null;
                this.query.endTime = null;
            }

            this.$store
                .dispatch('getLoginLogPage', this.query)
                .then((res) => {
                    this.list = res.list;
                    this.total = res.total;
                })
                .finally(() => {
                    this.listLoading = false;
                });
        },
        /**
         * 时间戳转码
         */
        formatDate(row, column) {
            // 获取单元格数据
            let data = row[column.property];
            if (!data) {
                return '';
            } else {
                return moment(data).format('YYYY-MM-DD HH:mm:ss');
            }
        },
    }
};
</script>
<style scoped>
.filter-container {
    padding: 20px;
}

.filter-item {
    margin-left: 10px;
}
</style>
